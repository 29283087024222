import { useEffect, useRef } from 'react';

interface ReturnInterface {
  intervalRef: React.MutableRefObject<NodeJS.Timer | undefined>;
}

const useInterval = (callback: () => any, delay: number): ReturnInterface => {
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    // initial load
    callback();

    if (!!intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      callback();
    }, delay);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return {
    intervalRef,
  };
};

export default useInterval;
