import { ReactNode } from 'react';
import moment from 'moment';
import { CHAINS, RESOLUTION_TIME } from './utils-webhook';
import { formatLargeNumber } from './utils-helper';
import { keyStats } from '../components/AppStatistical';
import config from '../config';
import { formatNumber } from './utils-format';
import BigNumber from 'bignumber.js';
import { NOT_AVAILABLE_TEXT } from './common';

export enum APP_STATUS {
  DISABLED = 0,
  ENABLE = 1,
}

export const RELOAD_INTERVAL_DURATION = 15000;

export interface IAppResponse {
  projectId: string;
  registrationId?: string;
  userId: string;
  name?: string;
  description?: string;
  chain: string;
  network: string;
  key: string;
  status: APP_STATUS;
  createdAt: number;
  totalWebhook?: number;
  numOfWebhook?: number;
  messageToday?: number;
}

export interface IDataMenu {
  value: string;
  icon?: ReactNode;
  label: ReactNode;
}

export const listStats: {
  key: string;
  label: string;
}[] = [
  {
    key: 'message',
    label: 'Total Messages (24hrs)',
  },
  {
    key: 'activities',
    label: 'Total Activities (24hrs)',
  },
  {
    key: 'successRate',
    label: 'Success Rate (24hrs)',
  },
  {
    key: 'webhooks',
    label: 'Active Webhook',
  },
];

export const listCUsStats: {
  key: string;
  label: string;
}[] = [
  {
    key: 'remainingCUs',
    label: 'Remaining CUs',
  },
  {
    key: 'cuUsage',
    label: 'CU Usage (This Period)',
  },
  {
    key: 'totalAPIRequest',
    label: 'Total API Request (24h)',
  },
  {
    key: 'activities',
    label: 'Triggers Notification (24h)',
  },
  {
    key: 'successRate',
    label: 'Success Rate (24h)',
  },
];

export const SAMPLE_DATA_CHART = {
  message: 0,
  activities: 0,
  successRate: 0,
  webhooks: 0,
  messagesSuccess: 0,
  messagesFailed: 0,
};

export interface IDataChart {
  activities: number;
  message: number;
  time: number;
}

export const getParamsChart = (duration: string) => {
  if (duration === '24h') {
    return {
      from: moment().utc().subtract(24, 'hour').valueOf(),
      to: moment().utc().valueOf(),
      resolution: RESOLUTION_TIME.HOUR,
    };
  }

  if (duration === '7d') {
    return {
      from: moment().utc().subtract(7, 'days').valueOf(),
      to: moment().utc().valueOf(),
      resolution: RESOLUTION_TIME.DAY,
    };
  }

  return {
    from: moment().utc().subtract(30, 'days').valueOf(),
    to: moment().utc().valueOf(),
    resolution: RESOLUTION_TIME.DAY,
  };
};

export const isChartEmpty = (dataChart: IDataChart[]) => {
  return (
    !dataChart.length ||
    dataChart.every(
      (item: IDataChart) =>
        item.message === 0 &&
        dataChart.every((item: IDataChart) => item.activities === 0),
    )
  );
};

const getStartOfByResolution = (timestamp: number, resolution: number) => {
  return timestamp - (timestamp % resolution);
};

export const fillFullResolution = (
  from: number,
  to: number,
  resolution: number,
  data: any,
  sampleData: any,
) => {
  const dataByKey: any[] = [];
  data.map((e: any) => {
    dataByKey[e.time] = e;
  });

  const result = [];
  const convertedResolution = resolution * 1000;
  let fromStartOfByResolution = getStartOfByResolution(
    from,
    convertedResolution,
  );

  const toStartOfByResolution = getStartOfByResolution(to, convertedResolution);

  while (fromStartOfByResolution <= toStartOfByResolution) {
    if (!dataByKey[fromStartOfByResolution]) {
      result.push({
        ...sampleData,
        time: fromStartOfByResolution,
      });
    } else {
      result.push(dataByKey[fromStartOfByResolution]);
    }
    fromStartOfByResolution = fromStartOfByResolution + convertedResolution;
  }

  return result;
};

export const formatDataStatistics = (
  data: {
    message: number;
    activities: number;
    messagesFailed: number;
    messagesSuccess: number;
    successRate: string;
    webhooks: number;
  },
  totalWebhookActive?: number,
  totalWebhook?: number,
) => {
  return listStats.map((item) => {
    const getValue = (value?: number, total?: number) => {
      if (!total || !value) return NOT_AVAILABLE_TEXT;
      return `${formatLargeNumber(value)}/${formatLargeNumber(total)}`;
    };

    switch (item.key) {
      case 'message':
        return {
          ...item,
          value: `${formatLargeNumber(data.message)}`,
        };
      case 'activities':
        return {
          ...item,
          value: `${formatLargeNumber(data.activities)}`,
        };
      case 'successRate':
        if (
          data.messagesFailed > 1 &&
          data.messagesFailed === data.messagesSuccess
        ) {
          return {
            ...item,
            value: NOT_AVAILABLE_TEXT,
          };
        }

        return {
          ...item,
          value: +data.successRate || NOT_AVAILABLE_TEXT,
        };
      case 'webhooks':
        return {
          ...item,
          value: getValue(totalWebhookActive, totalWebhook),
        };
      default:
        return {
          ...item,
          value: data[item.key as keyStats] || NOT_AVAILABLE_TEXT,
        };
    }
  });
};

export const formatDataCUsStatistics = (data: {
  totalCu: number;
  cuUsage: number;
  totalApiRequest: number;
  activities: number;
  successRate: number;
}) => {
  const { totalCu, cuUsage, totalApiRequest, activities, successRate } = data;

  return listCUsStats.map((item) => {
    switch (item.key) {
      case 'remainingCUs':
        const remainingCUs = totalCu - cuUsage;
        return {
          ...item,
          value: formatNumber(
            BigNumber(remainingCUs).isGreaterThan(0) ? remainingCUs : 0,
          ),
        };
      case 'cuUsage':
        return {
          ...item,
          value: formatNumber(cuUsage),
        };
      case 'totalAPIRequest':
        return {
          ...item,
          value: formatNumber(totalApiRequest),
        };
      case 'activities':
        return {
          ...item,
          value: formatNumber(activities),
        };
      default: // successRate
        return {
          ...item,
          value: +successRate || NOT_AVAILABLE_TEXT,
        };
    }
  });
};

export const CHAINS_CONFIG = Object.keys(config.chains)
  .filter(
    (chainKey) => ![CHAINS.SUI, CHAINS.AVAX, CHAINS.APTOS].includes(chainKey), // remove AVAX, APTOS in staging (tmp)
  )
  .map((chainKey) => {
    const chain = config.chains[chainKey];
    const networksClone = Object.keys(chain.networks).map((networkKey) => {
      const network = chain.networks[networkKey];
      return { label: network.name, value: network.id, icon: network.icon };
    });

    return {
      label: chain.name,
      value: chain.id,
      icon: chain.icon,
      networks: [...networksClone],
    };
  });
