import React, { useCallback, useMemo, useState } from 'react';
import rf from 'src/requests/RequestFactory';
import moment from 'moment';
import { RESOLUTION_TIME } from 'src/utils/utils-webhook';
import BigNumber from 'bignumber.js';

import {
  SAMPLE_DATA_CHART,
  fillFullResolution,
  formatDataCUsStatistics,
  RELOAD_INTERVAL_DURATION,
} from 'src/utils/utils-app';
import AppListStatistics from '../../../components/AppListStatistics';
import useInterval from 'src/hooks/useInterval';

interface IUserStats {
  activities: number;
  cuUsage: number;
  message: number;
  messagesFailed: number;
  messagesSuccess: number;
  totalApiRequest: number;
  totalCu: number;
}

const PartUserStats = () => {
  const [userStatsToday, setUserStatsToday] = useState<IUserStats | any>({});
  const [dataChart, setDataChart] = useState<IUserStats[]>([]);

  const getUserStats = useCallback(async () => {
    const formTime = moment().utc().subtract(24, 'hour').valueOf();
    const toTime = moment().utc().valueOf();

    try {
      const responses = await Promise.allSettled([
        rf.getRequest('NotificationRequest').getUserStats24h(),
        rf.getRequest('NotificationRequest').getUserStats({
          from: formTime,
          to: toTime,
          resolution: RESOLUTION_TIME.HOUR,
        }),
      ]);

      const [userStats24h, userStatsChart] = responses;

      if (userStats24h.status === 'fulfilled' && !!userStats24h.value) {
        const {
          totalCu,
          cuUsage,
          totalApiRequest,
          activities,
          messagesSuccess,
          message,
        } = userStats24h.value;

        setUserStatsToday({
          ...userStatsToday,
          totalCu,
          cuUsage,
          totalApiRequest,
          activities,
          successRate: !!message
            ? new BigNumber(messagesSuccess / message * 100).toFixed(2).toString()
            : 0,
        });
      }

      if (
        userStatsChart.status === 'fulfilled' &&
        !!userStatsChart.value.length
      ) {
        const dataFilled = fillFullResolution(
          formTime,
          toTime,
          RESOLUTION_TIME.HOUR,
          userStatsChart.value,
          SAMPLE_DATA_CHART,
        );

        setDataChart(dataFilled);
      }
    } catch (error: any) {
      setDataChart([]);
    }
  }, []);

  useInterval(getUserStats, RELOAD_INTERVAL_DURATION);

  const dataUserStatsToday = useMemo(() => {
    return formatDataCUsStatistics(userStatsToday);
  }, [userStatsToday]);

  return (
    <AppListStatistics dataStats={dataUserStatsToday} dataChart={dataChart} />
  );
};

export default PartUserStats;
